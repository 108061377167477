import { BaseUrl } from "./https";

function capitalizeFirstLetter(str) {
  if (str && typeof str === "string") {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
}

function getByLan(text, lang) {
  if (text && text !== "undefined" && text !== "null") {
    if (text?.indexOf("/") > -1) {
      return lang === "fr" ? text?.split("/")[0] : text?.split("/")[1];
    } else {
      return text;
    }
  }
  return "";
}

function isArrayJSON(jsonData) {
  try {
    const parsedData = JSON.parse(jsonData);
    return Array.isArray(parsedData);
  } catch (error) {
    return false; // Invalid JSON format
  }
}

function getImage(item) {
  if (item && item !== "null") {
    return item?.isNew
      ? `${BaseUrl}/images/${item?.name}`
      : "https://ceedow.fr/ceedow/Yumepro2021v1.1/" + item?.name;
  } else {
    return "https://images.assetsdelivery.com/compings_v2/julynx/julynx1408/julynx140800023.jpg";
  }
}

const isEmailValid = (email) => {
  const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  return email !== undefined && regexEmail.test(email.trim());
};

const isValidPassword = (password) => {
  return /^(?=.*?).{4,}$/.test(password);
};

const isValidPhoneNumber = (phonenumber) => {
  return /\+?([0-9]{7,})$/.test(phonenumber);
};

export {
  capitalizeFirstLetter,
  isEmailValid,
  isValidPassword,
  isValidPhoneNumber,
  getByLan,
  isArrayJSON,
  getImage,
};
