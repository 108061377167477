/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Link } from 'react-router-dom';

const SideBar = () => {
    return (
        <section id="sidebar">
            <Link to="/" className="brand">
                <i className='bx bxs-smile'></i>
                <span className="text">Ceedow</span>
            </Link>
        </section>
    );
}

export default SideBar 