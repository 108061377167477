import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy } from "react";
import Layout from "../components/Layout/Layout";
import LazyBoundary from "../components/LazyBoundary/LazyBoundary";
import Login from "../components/Login/Login";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import NotFound from "../components/NotFound/NotFound";
import ForgotPwd from "../components/ForgotPwd/ForgotPwd";
import ConfirmPwd from "../components/ConfirmPwd/ConfirmPwd";

const Chat = lazy(() => import("../components/Chat/Chat"));

const Home = lazy(() => import("../components/Home/Home"));

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route
            index
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/chat/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
        </Route>
        <Route
          path="/login"
          element={
            <ProtectedRoute isPublic={true}>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ProtectedRoute isPublic={true}>
              <ForgotPwd />
            </ProtectedRoute>
          }
        />
        <Route
          path="/confirm-password"
          element={
            <ProtectedRoute isPublic={true}>
              <ConfirmPwd />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
