import "./App.css";
import { ConfirmationProvider } from "./components/ConfirmationContext/ConfirmationContext";
import Router from "./router/router";

function App() {
  return (
    <ConfirmationProvider>
      <Router />
    </ConfirmationProvider>
  );
}
export default App;
