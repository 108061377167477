import * as React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import { toast } from "react-toastify";
import SnackBar, { tostConfig } from "../Common/SnackBar/SnackBar";
import https from "../../utils/https";
import { ConfirmationContext } from "../ConfirmationContext/ConfirmationContext";
import GoBack from "../Common/GoBack/GoBack";

const ForgotPwd = () => {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const { setConfirmation } = React.useContext(ConfirmationContext);

  const [user, setUser] = React.useState({
    email: "",
    password: "",
  });

  const handleError = (message) => {
    setIsLoading(false);
    toast.error(message, {
      ...tostConfig,
      bodyStyle: { color: "red", textAlign: "center" },
    });
  };

  const handleSendEmail = (code, token) => {
    https
      .post("/resetPassword", {
        email: user.email,
        code,
        email_sender: "no-reply@ceedow.fr"
      })
      .then(() => {
        setConfirmation({
          email: user.email,
          password: user.password,
          code,
          token,
        });
        navigation("/confirm-password");
      })
      .catch((err) => {
        console.log(err)
        handleError(
          "Une erreur esr produite, alors veuillez réessayer, s'il vous plait !!!"
        );
      });
  };

  const handleCheckedUser = () => {
    https
      .get(`/admin/getEmail/${user.email}`)
      .then((response) => {
        if (response.data && response.data.admin.length) {
          const code = response.data.code;
          const token = response.data.token;
          if (code && token) {
            handleSendEmail(code, token);
          } else {
            handleError(
              "Une erreur esr produite, alors veuillez réessayer, s'il vous plait !!!"
            );
          }
        } else {
          handleError(
            "Votre compte est introuvable, alors veuillez contacter le support"
          );
        }
      })
      .catch((e) => {
        handleError(
          "Votre compte est introuvable, alors veuillez contacter le support"
        );
      });
  };

  const handleChangePasswords = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleCheckedUser();
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="body-grid">
      <div className="container">
        <div className="col-md-6 mx-auto grid-forgotPawd">
          <h1>Récupération de compte</h1>
          <p>
            Afin de protéger votre compte et changer votre mot de passe, Ceedow
            veut s'assurer que c'est bien vous qui essayez de vous connecter
          </p>
          <form onSubmit={handleChangePasswords}>
            <input
              required
              type="mail"
              name="email"
              value={user.email}
              placeholder="Entrer votre mail..."
              onChange={handleChange}
            ></input>
            <input
              type="password"
              name="password"
              value={user.password}
              required
              placeholder="Entrer votre nouveau mot de passe..."
              onChange={handleChange}
            ></input>
            <br />
            <div className="footer_btn">
              <GoBack />
              {isLoading ? (
                <Loading />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Valider
                </button>
              )}
            </div>
          </form>
        </div>
        <SnackBar />
      </div>
    </div>
  );
};

export default ForgotPwd;
