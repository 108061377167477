import * as React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import { ConfirmationContext } from "../ConfirmationContext/ConfirmationContext";
import { BaseUrl } from "../../utils/https";
import axios from "axios";
import { toast } from "react-toastify";
import SnackBar, { tostConfig } from "../Common/SnackBar/SnackBar";
import { setAuthToken } from "../../utils/authToken";
import GoBack from "../Common/GoBack/GoBack";

const ConfirmPwd = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [code, setCode] = React.useState("");

  const { confirmation } = React.useContext(ConfirmationContext);
  const navigation = useNavigate();

  const handleError = (message) => {
    setIsLoading(false);
    toast.error(message, {
      ...tostConfig,
      bodyStyle: { color: "red", textAlign: "center" },
    });
  };

  React.useEffect(() => {
    if (!confirmation.code) {
      navigation("/login");
    }
  }, []);

  const handleValidPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (code === confirmation.code) {
      const { password, email, token } = confirmation;
      const data = { password, email };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `ceedow_key ${token}` : "",
        },
      };
      axios
        .post(`${BaseUrl}/api/admin/updatePassword`, data, config)
        .then((response) => {
          setIsLoading(false);
          window.location.href = "/login";
        })
        .catch((error) => {
          handleError(
            "Une erreur est produite, alors veuillez réessayer, s'il vous plait !!!"
          );
        });
    } else {
      handleError(
        "Veuillez verifier le code de confirmation, s'il vous plait !!!"
      );
    }
  };

  return (
    <div className="body-grid conf">
      <div className="container">
        <div className="col-md-6 mx-auto grid-forgotPawd">
          <h1>Validation de nouveau mot de passe</h1>
          <p>
            Afin de valider le nouveau mot de passe, veuillez entre le code 4
            chiffres qui est envoyé à l'adresse email:{" "}
            <b>{confirmation.email}</b>
          </p>
          <form onSubmit={handleValidPassword}>
            <input
              required
              maxLength={4}
              minLength={4}
              type="text"
              placeholder="Entrer votre code"
              value={code}
              onChange={(event) => setCode(event.target.value)}
            ></input>
            <br></br>
            <div className="footer_btn">
              <GoBack />
              {isLoading ? (
                <Loading />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Confirmer
                </button>
              )}
            </div>
          </form>
          <br></br>
        </div>
      </div>
      <SnackBar />
    </div>
  );
};

export default ConfirmPwd;
