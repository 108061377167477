export const setAuthToken = (token) => {
  sessionStorage.setItem("token-ceedow", JSON.stringify(token));
};

export const getAuthToken = () => {
  return JSON.parse(sessionStorage.getItem("token-ceedow"));
};

export const removeAuthToken = () => {
  sessionStorage.removeItem("token-ceedow");
};
