import * as React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className='pageError'>
            <div className='page-error'>

                <h1>404</h1>
                <h2 className='mb-2'>Page introuvable!!!</h2>
                <p>La page que vous essayez d'atteindre est intrvable</p>
                <Link className='btnError' to='/'>Retour à l'accueil</Link>

            </div>
        </div>
    );
}

export default NotFound 