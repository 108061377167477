import React, { createContext, useState } from "react";

const defaultValue = {
  email: "",
  password: "",
  code: "",
  token: "",
};
const ConfirmationContext = createContext();

const ConfirmationProvider = ({ children }) => {
  const [confirmation, setConfirmation] = useState(defaultValue);
  return (
    <ConfirmationContext.Provider
      value={{
        confirmation,
        setConfirmation,
      }}
    >
      {children}
    </ConfirmationContext.Provider>
  );
};

export { ConfirmationContext, ConfirmationProvider };
