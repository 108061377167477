import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import SideBar from './SideBar/SideBar';

const Layout = () => {
    return (
        <div>
            <SideBar/>
            <section id="content">
                <Header/>
                <main>
                    <Outlet />
                </main>
            </section>
            <Footer/>
        </div>
    );
}

export default Layout 