import axios from "axios";
import { getAuthToken } from "./authToken";

const BaseUrl = "https://webservice.ceedow.world";

const token = getAuthToken();

const https = axios.create({
  baseURL: BaseUrl + "/api",
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `ceedow_key ${token.token}` : "",
  },
  params: {
    id_auth: token ? token.id : "",
  },
});

export { BaseUrl };
export default https;
