/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { getAuthToken } from "../../utils/authToken";
import { useLocation, useNavigate } from "react-router-dom"

const ProtectedRoute = ({ children, isPublic }) => {
    const location = useLocation();
    const accessToken = getAuthToken();
    const navigation = useNavigate()

    useEffect(() => {
        if (!accessToken && !isPublic) {
            // Redirect to login page
            navigation('/login')
        }
        if (accessToken && isPublic) {
            // Redirect to home page
            navigation('/')
        }
    }, [accessToken, location.pathname])

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );

};

export default ProtectedRoute;